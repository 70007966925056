<template>
  <div class="course-detail" v-if="course" @click="handleCourse">
    <div class="course-cover">
      <span class="state" v-if="course.courseType === '2'">直播</span>
      <el-image style="width: 100%;height: 100%" :src="course.imageUrl || selectDefaultImg(course.id)"
                fit="fill"></el-image>
    </div>
    <div class="course-info">
      <div class="course-title">
        <p>{{ course.title }}</p>
      </div>
      <div class="course-state">
        <div class="state">{{ handleFilterStatus(course.status) }}</div>
        <div class="price"> {{ course.unitPrice > 0 ? `¥ ${course.unitPrice}` : '免费' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      statusOptions: [
        {
          dictValue: '0',
          dictLabel: "待上传凭证",
        },
        {
          dictValue: '1',
          dictLabel: "待寄出发票",
        },
        {
          dictValue: '2',
          dictLabel: "待确认收款",
        },
        {
          dictValue: '3',
          dictLabel: "已完成",
        },
        {
          dictValue: '4',
          dictLabel: "已驳回",
        },
        {
          dictValue: '5',
          dictLabel: "已取消",
        },
      ],
    }
  },
  methods: {
    handleFilterStatus(status) {
      return status === '1' ? '平台已确认' : status === '2' ? '平台待确认' : this.filterStatus(status)
    },
    filterStatus(status) {
      const arr = this.statusOptions.find(item => item.dictValue === status)
      console.log(arr)
      return arr?.dictLabel
    },
    handleCourse() {
      this.$router.push({path: `/signProgress?id=${this.course.id}`})
    }
  }
}
</script>

<style scoped lang="scss">
.course-detail {
  width: 238px;
  border-radius: 6px;
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  margin-bottom: 26px;
  overflow: hidden;
  cursor: pointer;

  .course-cover {
    height: 128px;
    position: relative;

    .state {
      position: absolute;
      right: 8px;
      top: 4px;
      padding: 4px 6px;
      border-radius: 6px;
      background: #1d70ea;
      color: #FFFFFF;
      font-size: 14px;
      z-index: 99;
    }
  }

  .course-info {
    .course-title {
      box-sizing: border-box;
      padding: 10px 12px;
      height: 46px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eff2f6;

      p {
        margin: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
      }
    }

    .course-state {
      padding: 10px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;

      .state {
        color: #1d70ea;
      }

      .price {
        font-weight: 500;
      }
    }
  }
}
</style>
