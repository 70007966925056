<template>
  <div>
    <div class="state-wrapper">
      <div class="state" :class="item.value===pitch?'active':''" v-for="item in states" :key="item.value"
           @click="handleSelected(item.value)">{{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    states: {
      type: Array,
      default: () => []
    },
    selected: {
      type:String,
      default: ''
    }
  },
  data() {
    return {
      pitch:''
    }
  },
  methods: {
    handleSelected(e) {
      this.$emit('handleChange', e)
    }
  },
  mounted() {
    this.pitch = this.selected
  },
  watch:{
    selected(e){
      this.pitch = e
    }
  }
}
</script>

<style scoped lang="scss">
.state-wrapper {
  display: flex;
  align-items: center;

  .state {
    margin-right: 32px;
    width: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 18px;
    cursor: pointer;

    &.active {
      background: linear-gradient(-54deg, rgba(30, 112, 234, 0.89), rgba(72, 140, 240, 0.89));
      color: #FFFFFF;
    }
  }
}
</style>
